<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: left">
          <h4>
            <p class="text-primary"> {{ profile_name }} 
              <a href="javascript:void(0)" @click="updateProfileNameModal = true"><md-icon style="color: #00bcd4 !important">edit</md-icon></a>
            </p>
          </h4>
      </div>
      <div style="float: right">
          <md-button class="md-success mr-2" :disabled="isDisabled" @click="ApplyPolicy()">
            Apply Policy<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
          <md-button class="md-danger mr-2" @click="$router.push('device-profiles')">Back</md-button>
      </div>
      <tabs
        :tab-name="[
          'Applications',
          'Restrictions',
          'KIOSK',
          'Devices',
          'Password Policy',
          'Content',
          'Wifi',
          'System Update',
          'Location',
          'Samsung KNOX',
          'Qr Code',
          'Advance Settings'
        ]"
        color-button="warning"
      >
        <!-- here you can add your content for tab-content -->
        <template slot="tab-pane-1">
          <md-card>
            <md-card-content>
              <div class="md-layout" v-if="applications">
                <div class="md-layout-item md-size-25">
                  <md-field>
                    <md-input type="text" placeholder="Search Applications" v-model="search_package_name"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                  <md-button class="md-primary" :disabled="isDisabled" @click="GetApplicationList()">Search</md-button> 
                  <!--&nbsp;<md-button class="md-success" @click="$router.push({ path: 'applications'})">More Apps</md-button>-->
                </div>
              </div>
              <div class="md-layout">
                <h4 v-if="applications.length == 0">No Application found</h4>
                <div class="app-listing-copy" v-if="applications">
                  <ul id="product-directory">
                    <li v-for="app in applications" :key="app._id">
                        <img :src=app.app_icon style="">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.package_name,app.is_web_app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.package_name" v-if="!app.application_details"><small><b>{{app.package_name }}</b></small></span>
                          <span class="apptext" :title="app.package_name" v-else><small><b>{{app.application_details.title }}</b>  ({{ app.package_name }})</small></span>
                        </div>
                    </li>
                    <!-- Play Store Application Append -->
                    <li v-for="app in storeApps" :key="app.appId">
                        <img :src=app.icon style="opacity: 0.5;">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.appId, false, app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.package_name"><small><b>{{app.title }}</b>  ({{ app.appId }})</small></span>
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
              <md-table
                v-model="profileApplications"
                table-header-color="green"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Package Name">
                    <div style="display:flex;">
                      <img :src="item.app_icon" style="height: 50px;width: 50px;" />&nbsp;
                    <p>{{ item.display_text }} <span v-if="item.has_title"> ({{ item.package_name }})</span></p>
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="App Disabled">
                    <input type="checkbox"  v-if="item.is_app_disabled == true" checked
                      :id="item._id" @change="UpdateAppEnableDisableStatus(item._id)">
                    <input type="checkbox"  v-else
                      :id="item._id" @change="UpdateAppEnableDisableStatus(item._id)">
                  </md-table-cell>
                  <md-table-cell md-label="App Status">
                    <select class="form-control" @change="ChangeInstallType(item.package_name,$event)">
                      <option value="AVAILABLE" v-bind:selected="item.install_type == 'AVAILABLE'">AVAILABLE</option>
                      <option value="PREINSTALLED" v-bind:selected="item.install_type == 'PREINSTALLED'">INSTALLED</option>
                      <option value="FORCE_INSTALLED" v-bind:selected="item.install_type == 'FORCE_INSTALLED'">FORCE INSTALLED</option>
                      <option value="BLOCKED" v-bind:selected="item.install_type == 'BLOCKED'">BLOCK & UNINSTALL</option>
                      <option value="REQUIRED_FOR_SETUP" v-bind:selected="item.install_type == 'REQUIRED_FOR_SETUP'">REQUIRED FOR SETUP</option>
                    </select>  
                  </md-table-cell>
                  <md-table-cell md-label="App Update">
                    <select class="form-control" @change="ChangeAutoUpdateMode(item.package_name,$event)">
                      <option value="AUTO_UPDATE_DEFAULT" v-bind:selected="item.auto_update_mode == 'AUTO_UPDATE_DEFAULT'">AUTO UPDATE</option>
                      <option value="AUTO_UPDATE_POSTPONED" v-bind:selected="item.auto_update_mode == 'AUTO_UPDATE_POSTPONED'">AUTO UPDATE POSTPONED</option>
                      <option value="AUTO_UPDATE_HIGH_PRIORITY" v-bind:selected="item.auto_update_mode == 'AUTO_UPDATE_HIGH_PRIORITY'">AUTO UPDATE HIGH PRIORITY</option>
                    </select>  
                  </md-table-cell>
                  <md-table-cell md-label="Actions">
                    <div style="display: flex;">                    
                      <md-button class="md-just-icon md-danger md-simple" @click="DeleteProfileApplication(item.package_name)"><md-icon>close</md-icon></md-button>
                      <md-button class="md-just-icon md-warning md-simple" @click="$router.push({ path: 'manage-app-config', query: { package_name: item.package_name, profile_id:  profile_id  }})"><md-icon>settings</md-icon></md-button>
                      <md-button class="md-just-icon md-primary md-simple" @click="GetApplicationPermission(item.package_name)"><md-icon>key</md-icon></md-button>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-2">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-success" :disabled="isDisabled" @click="UpdateProfile()">
                  Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
              </div>
              <br /><br />
              <collapse
                :collapse="['General', 'Network', 'System']"
                icon="keyboard_arrow_down"
                color-collapse="success"
              >
                <template slot="md-collapse-pane-1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-card>
                        <md-card-header>
                          <h4 class="title"><b>Communication Settings</b></h4>
                        </md-card-header>
                        <md-card-content>
                          <div class="md-layout-item">
                            <md-switch v-model="outgoing_calls_disabled">Disable Outgoing Calls</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="sms_disabled">Disable SMS</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="bluetooth_disabled">Disable Bluetooth</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="outgoing_beam_disabled">Disable Android Beam</md-switch>
                          </div>
                        </md-card-content>
                      </md-card>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-card>
                        <md-card-header>
                          <h4 class="title">
                            <b>User & Account Management</b>
                          </h4>
                        </md-card-header>
                        <md-card-content>
                          <div class="md-layout-item">
                            <md-switch v-model="add_user_disabled">Disable Adding Users</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="remove_user_disabled">Disable Removing Users</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="modify_accounts_disabled">Disable Adding/Deleting Accounts</md-switch>
                          </div>
                        </md-card-content>
                      </md-card>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-card>
                        <md-card-header>
                          <h4 class="title">
                            <b>Network & Security Settings</b>
                          </h4>
                        </md-card-header>
                        <md-card-content>
                          <div class="md-layout-item">
                            <md-switch v-model="mobile_networks_config_disabled">Disable Network Changes</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="tethering_config_disabled">Disable Tethering</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="wifi_config_disabled">Disable WiFi Changes</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="factoryResetDisabled">Disable Factory Reset</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="screen_capture_disabled">Disable Screen Capture</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="camera_disabled">Disable Camera</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="developer_settings">Disable Developer Setting</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="install_unknown_sources_allowed ">Installation From Unkown Source</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="enableFullPlayStore">Enable Full Play Store</md-switch>
                          </div>
                          
                          <!--<div class="md-layout-item">
                            <md-switch v-model="safe_boot_disabled">Disabled Safe Boot</md-switch>
                          </div>-->
                        </md-card-content>
                      </md-card>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <md-card>
                        <md-card-header>
                          <h4 class="title">
                            <b>Keyguard & Notification Settings</b>
                          </h4>
                        </md-card-header>
                        <md-card-content>
                          <div class="md-layout-item">
                            <md-switch v-model="keyguard_disabled">Disable Keyguard</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="status_bar_disabled">Disable Notification Bar</md-switch>
                          </div>
                          <div class="md-layout-item">
                            <md-switch v-model="adjust_volume_disabled">Disable Volume</md-switch>
                          </div>
                        </md-card-content>
                      </md-card>
                    </div>
                  </div>
                </template>
                <template slot="md-collapse-pane-2">
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <md-switch v-model="volume_key">LTE Mode</md-switch>
                    </div>
                  </div>
                </template>
                <template slot="md-collapse-pane-3">
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <md-switch v-model="volume_key">S Voice</md-switch>
                    </div>
                  </div>
                </template>
              </collapse>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-3">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <!--<md-button class="md-danger" v-if="this.package_name" @click="ExitKioskMode()">Exit</md-button>&nbsp;-->
                <md-button class="md-success" :disabled="isDisabled" @click="UpdateProfile('KIOSK')">
                  Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
              </div>
              <br /><br />
              <div class="md-layout-item md-size-100">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="None">None</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="singleApp" key="101">Single App</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="MULTI APP" key="102">Multi App</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="BROWSER" key="103">Browser</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="SIGNAGE" key="104">Signage</md-radio>
                  </div>
                </div>
              </div>
              

              <div class="md-layout">
                <div class="md-layout-item md-size-60 kiosk-div">
                    <!-- Single App Kiosk-->  
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'singleApp'">
                      <div v-if="profileApplicationsKiosk.length > 0">
                        <h4><b>Select one application to lockdown your devices.</b></h4>
                        <ul id="product-directory">
                          <li class="li-class" v-for="app in profileApplicationsKiosk" :key="app._id">
                            <input type="radio" class="radio-d-none" name="package_name" v-model="package_name" :value="app.package_name" :id="app._id" />
                            <label class="label" :for="app._id"><img :src="app.app_icon" class="app-icon-single" /></label>
                            <span class="apptext">
                              <small><b>{{ app.display_text }}</b></small>
                              <small v-if="app.has_title">({{ app.package_name }})</small>
                            </span> 
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- Multi App Kiosk-->
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'MULTI APP'">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                          <!--<md-checkbox class="custom-checkbox" v-model="multi_app_kiosk_google_custom_launcher">
                            Enable Google Custom Kiosk
                          </md-checkbox>-->
                          <p>Enable Kiosk custom launcher with all approved apps. To add or remove apps from the custom launcher please use the Applications menu.</p>
                          <p><small class="text-danger"><b>Note: Listed application will be force installed.</b></small></p>
                        </div>
                            <div v-if="profileApplicationsKiosk.length > 0">
                              <ul id="product-directory">
                                <li class="li-class" v-for="app in profileApplicationsKiosk" :key="app._id">
                                    <label class="label" :for="app._id"><img :src=app.app_icon class="app-icon-single" /></label>
                                    <a class="badge-icon" href="javascript:void(0);" style="color:black;" @click="DeleteProfileApplication(app.package_name)">
                                      <i class="fa fa-window-close" aria-hidden="true"></i>
                                    </a>
                                    <span class="apptext" :title="app.display_text">
                                      <small><b>{{ app.display_text }}</b></small>
                                      <small v-if="app.has_title">({{ app.package_name }})</small>
                                    </span>
                                </li>
                              </ul>
                        </div>
                      </div>  
                    </div>

                    <!-- Browser App Kiosk-->
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'BROWSER'">
                      <div class="md-layout">
                        <!--<div class="md-layout-item md-size-25">
                            <md-checkbox v-model="browser_kiosk_close_on_touch" class="custom-checkbox">Close On Touch</md-checkbox>
                        </div>
                        <div class="md-layout-item md-size-25">
                            <md-checkbox class="custom-checkbox" v-model="browser_kiosk_enable_screen_saver">Enable Screen Saver</md-checkbox>
                        </div>-->
                        <div class="md-layout-item md-size-100">
                            <md-field>
                              <label>Website Url</label>
                              <md-input type="text" v-model="browser_kiosk_website_url"></md-input>
                            </md-field>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Timeout 
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>Seconds</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="browser_kiosk_screen_timeout"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Brightness
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>0 to 255</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="browser_kiosk_screen_brightness" max="255"></md-input>
                            </md-field>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Orientation</label>
                              <md-select v-model="browser_kiosk_screen_orientation">
                                <md-option value="Auto">Auto</md-option>
                                <md-option value="Portrait">Portrait</md-option>
                                <md-option value="Landscape">Landscape</md-option>
                              </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-checkbox class="custom-checkbox" v-model="browser_kiosk_show_address_bar">Show Address Bar</md-checkbox>
                        </div>
                      </div>
                    </div>

                    <!-- Signage Kiosk-->
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'SIGNAGE'">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Timeout
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>Seconds</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="signage_kiosk_screen_timeout"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Brightness
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>0 to 255</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="signage_kiosk_screen_brightness"></md-input>
                            </md-field>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Orientation</label>
                              <md-select v-model="signage_kiosk_screen_orientation">
                                <md-option value="Auto">Auto</md-option>
                                <md-option value="Portrait">Portrait</md-option>
                                <md-option value="Landscape">Landscape</md-option>
                              </md-select>
                            </md-field>
                        </div>
                        <!--<div class="md-layout-item md-size-25">
                            <md-checkbox v-model="signage_kiosk_close_on_touch" class="custom-checkbox">Close On Touch</md-checkbox>
                        </div>-->
                      </div><br>
                      <div class="md-layout">
                          <h4 class="title"><b>Available Video List</b></h4> 
                            <small class="text-danger"> &nbsp; <b>Note :</b> 
                              If you want to add new link or upload new video then go to content tab and add video over their first.
                              <a href="javascript:voud(0)" @click="moveTo()"><b><md-icon style="color: #00bcd4 !important">add</md-icon></b></a>
                            </small>
                          <hr>
                          <div class="md-layout-item" v-for="content in profileContent" :key="content.content_link">
                              <md-checkbox class="custom-checkbox" v-model="signage_videos" :true-value="[]" :value="content.content_link">
                                  <span v-if="content.content_type == 'Manual'">{{ content.content_link }}</span>
                                  <span v-else>{{ content.upload_path }}</span>
                              </md-checkbox>
                          </div>
                      </div>
                      <!--<div class="md-layout">
                        <div v-for="(textField, i) in textFields" :key="i" style="width: 50%" >
                          <div class="md-layout-item text-fields-row">
                            <md-field>
                              <label>{{ textField.label1 }}</label>
                              <md-input type="text" v-model="textField.value1" name="video_list[]" ></md-input>
                            </md-field>
                            <md-button class="md-just-icon md-danger" @click="deleteTextBox(i)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </md-button>
                          </div>
                        </div>
                      </div>
                      <md-button class="md-primary" @click="addTextBox">Add Video</md-button>-->
                    </div>
                </div>
                <div class="md-layout-item md-size-40 kiosk-common-setting">
                    <div class="md-layout-item md-size-100">
                    <h4 class="card-title"><b>Common Settings</b></h4>
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100">
                          <md-field>
                            <label><b>Power Button Action</b></label>
                            <md-select v-model="kiosk_power_button_action" name="kiosk_power_button_action">
                              <md-option value="POWER_BUTTON_AVAILABLE">POWER BUTTON AVAILABLE</md-option>
                              <md-option value="POWER_BUTTON_BLOCKED">POWER BUTTON BLOCKED</md-option>
                            </md-select>
                          </md-field>
                      </div>
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <label><b>System App Warnings</b></label>
                          <md-select v-model="kiosk_system_app_warning" name="kiosk_system_app_warning">
                            <md-option value="ERROR_AND_WARNINGS_MUTED">ERROR AND WARNINGS MUTED</md-option>
                            <md-option value="ERROR_AND_WARNINGS_ENABLED">ERROR AND WARNINGS ENABLED</md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <label><b>System Navigation</b></label>
                          <md-select v-model="kiosk_system_navigation" name="kiosk_system_navigation">
                            <md-option value="NAVIGATION_DISABLED">NAVIGATION DISABLED</md-option>
                            <md-option value="NAVIGATION_ENABLED">NAVIGATION ENABLED</md-option>
                            <md-option value="HOME_BUTTON_ONLY">HOME BUTTON ONLY</md-option>
                          </md-select>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <label><b>Status Bar</b></label>
                          <md-select v-model="kiosk_status_bar" name="kiosk_status_bar">
                            <md-option value="NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED">NOTIFICATIONS & SYSTEM INFO DISABLED</md-option>
                            <md-option value="NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED">NOTIFICATIONS & SYSTEM INFO ENABLED</md-option>
                            <md-option value="SYSTEM_INFO_ONLY">SYSTEM INFO ONLY</md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <label><b>Device Setting</b></label>
                          <md-select v-model="kiosk_device_setting" name="kiosk_device_setting">
                            <md-option value="SETTINGS_ACCESS_ALLOWED">SETTINGS ACCESS ALLOWED</md-option>
                            <md-option value="SETTINGS_ACCESS_BLOCKED">SETTINGS ACCESS BLOCKED</md-option>
                          </md-select>
                        </md-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-4">
          <md-card>
              <md-card-content>
                <div style="float: right;">
                  <md-button class="md-danger mr-2" v-if="kiosk_type != 'None'" :disabled="isDeviceBtnDisabled" @click="exitDeviceKiosk()">Exit KIOSK <span v-if="isExitKioskDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                  <md-button class="md-primary mr-2" :disabled="isDeviceBtnDisabled" @click="IssueCommand('REBOOT')">Reboot</md-button>
                  <md-button class="md-success mr-2" :disabled="isDeviceBtnDisabled" @click="IssueCommand('LOCK')">Lock</md-button>
                  <md-button class="md-info mr-2" :disabled="isDeviceBtnDisabled" @click="ResetPasswordModal = true">Reset Password</md-button>
                  <md-button class="md-danger mr-2" :disabled="isDeviceBtnDisabled" v-if="disabledFactoryReset == false" @click="DeleteDevice()">{{ factoryResetText }}<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                  <md-button class="md-warning mr-2" :disabled="isDeviceBtnDisabled" @click="GetApplicationUsers()">Assign User</md-button>
                  <md-button class="md-secondary mr-2" :disabled="isDeviceBtnDisabled" @click="GetUserDevicePrrofiles()">Move Device</md-button>
                  <!--<md-button class="md-info f-right-padding" @click="RefreshProfileDevice()">Refresh List</md-button>&nbsp;-->
                </div>
                <br /><br />
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-switch v-model="search_only_deleted_device" @change="GetProfileDeviceList()">Only Deleted Devices</md-switch>
                    </div>
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <img :src="LoaderImage" class="loader-img" style="display:none;" />
                      <md-table v-model="profileDevices" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="Actions">
                            <div class="md-layout">
                              <div class="md-layout-item">
                                <md-checkbox :value="item.name" v-model="selected_devices" @change="select(item.managementMode)"></md-checkbox>
                              </div>
                            </div>
                          </md-table-cell>
                          <md-table-cell md-label="Serial Number">
                            <a @click="$router.push({ path: 'device', query: { device_id: item._id }})" href="javascript:void(0)">{{ item.serialNumber }}</a>
                            <label v-if="item.deleted_at">
                              <span class="deleted">Deleted</span>
                              <br><p>{{ HumanReadableDateFormat(item.deleted_at, true) }}</p>
                            </label>
                          </md-table-cell>
                          <md-table-cell v-if="item.user_id" md-label="User Name">{{
                            item.user_id.user_name
                          }}</md-table-cell>
                          <md-table-cell v-else md-label="User Name">-</md-table-cell>
                          <md-table-cell md-label="Brand" v-if="item.hardwareInfo">{{
                            item.hardwareInfo.brand
                          }}</md-table-cell>
                          <md-table-cell md-label="Model" v-if="item.hardwareInfo">{{
                            item.hardwareInfo.model
                          }}</md-table-cell>
                          <md-table-cell md-label="Enrollment Time">{{
                            HumanReadableDateFormat(item.enrollmentTime)
                          }}</md-table-cell>
                          <md-table-cell md-label="Last Policy SyncTime">{{
                            HumanReadableDateFormat(item.lastPolicySyncTime)
                          }}</md-table-cell>
                          <md-table-cell md-label="Last Status ReportTime">
                            <span v-if="item.lastStatusReportTime">{{ HumanReadableDateFormat(item.lastStatusReportTime) }}</span>
                            <span v-else>-</span>
                          </md-table-cell>
                          <!--<md-table-cell md-label="Ownership">{{
                            item.ownership
                          }}</md-table-cell>-->
                        </md-table-row>
                      </md-table>
                    </div>
                    </div>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-5">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button
                  class="md-success"
                  :disabled="isDisabled"
                  @click="UpdateProfile()"
                  >Save</md-button>
              </div>
              <br /><br />
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <h4 class="card-title">Basic Settings</h4>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Password Type
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordQuality"
                          name="passwordQuality"
                        >
                          <md-option value="PASSWORD_QUALITY_UNSPECIFIED"
                            >PASSWORD_QUALITY_UNSPECIFIED</md-option
                          >
                          <md-option value="BIOMETRIC_WEAK"
                            >BIOMETRIC_WEAK</md-option
                          >
                          <md-option value="SOMETHING">SOMETHING</md-option>
                          <md-option value="NUMERIC">NUMERIC</md-option>
                          <md-option value="NUMERIC_COMPLEX">NUMERIC_COMPLEX</md-option>
                          <md-option value="ALPHABETIC">ALPHABETIC</md-option>
                          <md-option value="ALPHANUMERIC">ALPHANUMERIC</md-option>
                          <md-option value="COMPLEX">COMPLEX</md-option>
                          <md-option value="COMPLEXITY_LOW">COMPLEXITY_LOW</md-option>
                          <md-option value="COMPLEXITY_MEDIUM">COMPLEXITY_MEDIUM</md-option>
                          <md-option value="COMPLEXITY_HIGH">COMPLEXITY_HIGH</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Min Password length
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordMinimumLength"
                          name="passwordMinimumLength"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value="4">4</md-option>
                          <md-option value="5">5</md-option>
                          <md-option value="6">6</md-option>
                          <md-option value="7">7</md-option>
                          <md-option value="8">8</md-option>
                          <md-option value="9">9</md-option>
                          <md-option value="10">10</md-option>
                          <md-option value="11">11</md-option>
                          <md-option value="12">12</md-option>
                          <md-option value="13">13</md-option>
                          <md-option value="14">14</md-option>
                          <md-option value="15">15</md-option>
                          <md-option value="16">16</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <h4 class="card-title">Password Management Settings</h4>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Password Expiry Period
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                          <md-input type="text" v-model="passwordExpirationTimeout" :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                            ].indexOf(passwordQuality) !== -1
                          "
                      ></md-input>
                      </md-field>
                      <small>A duration in seconds with up to nine fractional digits, ending with 's'. Example: "3.5s".</small>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Maximum Password History List
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select v-model="passwordHistoryLength"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value=0>Never</md-option>
                          <md-option value=1>Current Password</md-option>
                          <md-option value=5>Last 5 Password</md-option>
                          <md-option value=10>Last 10 Password</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Maximum Failed Attempt to Factory Reset
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select v-model="maximumFailedPasswordsForWipe"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value=0>Never</md-option>
                          <md-option value=3>After 3 Attempts</md-option>
                          <md-option value=5>After 5 Attempts</md-option>
                          <md-option value=10>After 10 Attempts</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Set Idle time for Auto Lock (in minutes)
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select v-model="maximumTimeToLock"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value=0>Never Autolock</md-option>
                          <md-option value=60000>1 minutes</md-option>
                          <md-option value=180000>3 minutes</md-option>
                          <md-option value=300000>5 minutes</md-option>
                          <md-option value=600000>10 minutes</md-option>
                          <md-option value=1800000>30 minutes</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <h4 class="card-title">Advance Settings</h4>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Minimum Number of Symbols
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordMinimumSymbols"
                          name="passwordMinimumSymbols"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                              'NUMERIC',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value="1">1</md-option>
                          <md-option value="2">2</md-option>
                          <md-option value="3">3</md-option>
                          <md-option value="4">4</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Minimum Number of Alphabets
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordMinimumLetters"
                          name="passwordMinimumLetters"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                              'NUMERIC',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value="1">1</md-option>
                          <md-option value="2">2</md-option>
                          <md-option value="3">3</md-option>
                          <md-option value="4">4</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Minimum Number of Lower-case Characters
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordMinimumLowerCase"
                          name="passwordMinimumLowerCase"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                              'NUMERIC',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value="1">1</md-option>
                          <md-option value="2">2</md-option>
                          <md-option value="3">3</md-option>
                          <md-option value="4">4</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Minimum Number of Upper-case Characters
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordMinimumUpperCase"
                          name="passwordMinimumUpperCase"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                              'NUMERIC',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value="1">1</md-option>
                          <md-option value="2">2</md-option>
                          <md-option value="3">3</md-option>
                          <md-option value="4">4</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout">
                    <label class="md-layout-item md-size-25 md-form-label">
                      Minimum Number of Digits
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-select
                          v-model="passwordMinimumNumeric"
                          name="passwordMinimumNumeric"
                          :disabled="
                            [
                              'PASSWORD_QUALITY_UNSPECIFIED',
                              'BIOMETRIC_WEAK',
                              'SOMETHING',
                            ].indexOf(passwordQuality) !== -1
                          "
                        >
                          <md-option value="1">1</md-option>
                          <md-option value="2">2</md-option>
                          <md-option value="3">3</md-option>
                          <md-option value="4">4</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-6">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-info" v-if="!cloud_storage_created" :disabled="isDisabled" @click="CreateNextCloudUser()">Create Cloud Storage</md-button>
              </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-70">
                    <md-radio class="custom-radio" v-model="content_type" value="Upload">Upload</md-radio>
                    <md-radio class="custom-radio" v-model="content_type" value="Manual">URL</md-radio>
                    <md-radio class="custom-radio" v-model="content_type" value="Global">From Another Profile</md-radio>
                  </div>
                  <div class="md-layout-item md-size-30">
                      <h4 class="title"><b>Storage Info</b></h4><hr>
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th>Total :</th>
                            <td>{{ formatSizeUnits(storage_total) }}</td>
                          </tr>
                          <tr>
                            <th>Used :</th>
                            <td>{{ formatSizeUnits(storage_used) }}</td>
                          </tr>
                          <tr>
                            <th>Free :</th>
                            <td>{{ formatSizeUnits(storage_available) }}</td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                </div>
              <div v-if="cloud_storage_created">
                <!-- Upload content -->
                <div class="md-layout" v-if="content_type == 'Upload'">
                  <div class="md-layout-item md-size-100">
                    <input type="file" @change="handleFileUpload( $event )"/>
                    &nbsp;&nbsp;
                    <md-button class="md-success" :disabled="isDisabled" @click="UploadContent()">Upload</md-button>
                  </div>
                  <img :src="LoaderImage" class="l-image" style="width: 150px;margin-left: 435px;margin-top: -98px;display:none;" />
                  <!--<progress max="100" :value.prop="uploadPercentage"></progress>-->
                </div>

                <!-- Manual content -->
                <div class="md-layout" v-if="content_type == 'Manual'">
                  <div class="md-layout-item md-size-100">
                    <md-input class="form-control w-50" style="height:45px;" v-model="content_link" placeholder="Enter Link" type="text"></md-input>
                    &nbsp;&nbsp;
                    <md-button class="md-success" :disabled="isDisabled" @click="ManuallyAddContent()">Add</md-button>
                  </div>
                </div>

                <!-- Global content -->
                <div class="md-layout" v-if="content_type == 'Global'">
                  <div class="md-layout-item md-size-50" style="display: -webkit-box">
                    <md-field>
                      <md-select v-model="content_id">
                        <md-option v-for="content in globalContent" v-bind:key="content._id" :value="content._id">
                          {{ content.upload_path }}
                        </md-option>
                      </md-select>
                    </md-field>
                    &nbsp;&nbsp;
                    <md-button class="md-success" :disabled="isDisabled" @click="GlobalAddContent()">Add</md-button>
                  </div>
                </div>

              </div>
              <br>
              <div class="md-layout">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    <small class="text-danger"><b>Note : Use Config Variable in application configuration for setting content url value in applications.</b></small>
                    <md-table v-model="profileContent" table-header-color="green">
                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Preview">
                          <div v-if="item.content_type == 'Upload' || item.content_type == 'Global'">
                            <img v-if="item.stat && item.stat.mime.includes('image')" :src="item.content_link" style="height: 75;width: 75px;" :id="item._id" @click="openFullscreen(item._id)" />
                            <video v-else-if="item.stat && item.stat.mime.includes('video')" width="75" height="75" :id="item._id" @click="openFullscreen(item._id)">
                              <source :src="item.content_link" type="video/mp4">
                            </video>
                            <span v-else>-</span>
                          </div>
                          <div v-else>
                            -
                          </div>
                        </md-table-cell>
                        <md-table-cell md-label="Upload Path">
                          <span v-if="item.content_type == 'Manual'">
                            <label v-if="item.content_link.length < 30">{{ item.content_link }}</label>
                            <label v-else :title="item.content_link">{{ item.content_link.substring(0,25)+".." }}</label>
                          </span>
                          <span v-else>
                            <label v-if="item.upload_path.length < 30">{{ item.upload_path }}</label>
                            <label v-else :title="item.upload_path">{{ item.upload_path.substring(0,25)+"..." }}</label>
                          </span>
                          <a href="javascript:void(0);" class="md-just-icon md-success md-simple" @click="CopyProfileContent(item.content_link)"><md-icon class="icon-success">content_copy</md-icon></a>
                          <br>
                          <label><b>Type : </b></label>
                          <span class="text-danger" v-if="item.content_type=='Upload'"><b>{{ item.content_type }}</b></span>
                          <span class="text-info" v-if="item.content_type=='Manual'"><b>URL</b></span>
                          <span class="text-primary" v-if="item.content_type=='Global'"><b>{{ item.content_type }}</b></span>
                          <label><b>, Size :</b>
                          <span class="text-primary" v-if="item.stat"><b>{{ formatSizeUnits(item.stat.size) }}</b></span>
                          <span class="text-primary" v-else><b>-</b></span>
                          <br>
                          <span class="text-warning"><b>{{ item.config_vairable }}</b></span>
                          <a class="md-just-icon md-success md-simple" href="javascript:void(0);" @click="CopyProfileContent(item.config_vairable)"><md-icon class="icon-success">content_copy</md-icon></a>
                          </label> 
                        </md-table-cell>
                        <md-table-cell v-if="item.user_id" md-label="User Name">{{ item.user_id.user_name  }}
                          - <a href="javascript:void(0);" class="md-just-icon md-danger md-simple" @click="DeleteContentUser(item._id)">
                              <md-icon class="icon-simple">close</md-icon>
                            </a>
                        </md-table-cell>
                        <md-table-cell v-else md-label="User">
                          <a href="javascript:void(0);" @click="OpenAddContentUserPopup(item._id)">
                              <i class="fa fa-edit text-primary" aria-hidden="true"></i>
                          </a>
                        </md-table-cell>
                        <md-table-cell md-label="Note"> 
                          <div>
                            <md-field>
                                <md-input :value="item.notes" type="text" :id="'note_' + item._id"></md-input>
                            </md-field>
                            &nbsp;<a href="javascript:void(0);" @click="UpdateProfileContentNote(item._id)">
                                <i class="fa fa-save text-primary" aria-hidden="true"></i>
                            </a>
                          </div>
                        </md-table-cell>
                        <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                        <md-table-cell md-label="Actions">
                          <md-button class="md-just-icon md-danger md-simple" @click="DeleteProfileContent(item._id, item.content_type)"><md-icon>close</md-icon></md-button>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
              </div>  
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-7">
          <md-card>
              <md-card-content>
                <div style="float: right;padding-right: 15px;">
                  <md-button class="md-success" @click="AddWifiModal = true">Add</md-button>
                </div>
                <br /><br />
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <md-table v-model="profileWifi" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="ssid">{{ item.wifi_ssid }}</md-table-cell>
                          <md-table-cell md-label="security">{{ item.wifi_security }}</md-table-cell>
                          <md-table-cell md-label="passphrase">{{ item.wifi_passphrase }}</md-table-cell>
                          <md-table-cell md-label="Auto Connect">{{ item.wifi_autoconnect }}</md-table-cell>
                          <md-table-cell md-label="Actions">
                            <md-button class="md-just-icon md-danger" @click="DeleteProfileWiFi(item._id)"><md-icon>delete</md-icon></md-button>
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                    </div>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-8">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-success" :disabled="isDisabled" @click="UpdateProfile()">Save</md-button>
              </div>
              <br />
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                      <label>System Update Type</label>
                      <md-select v-model="system_update_type" name="system_update_type">
                        <md-option value="AUTOMATIC">AUTOMATIC</md-option>
                        <md-option value="WINDOWED">WINDOWED</md-option>
                        <md-option value="POSTPONE">POSTPONE</md-option>
                      </md-select>
                    </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Start Time</label>
                    <md-input type="number" max="24" v-model="system_update_start_time" 
                      :disabled="system_update_type != 'WINDOWED'"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>End Time</label>
                    <md-input type="number" max="24" v-model="system_update_end_time"
                      :disabled="system_update_type != 'WINDOWED'"></md-input>
                  </md-field>
                </div>
              </div>
              <hr>
              <h4><b>Annual System Update Freeze Period</b></h4>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <md-field>
                      <md-datepicker v-model="system_update_freeze_start_date" md-immediately :disabledDates="disabledDates">
                        <label>Start Date</label>
                      </md-datepicker>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <md-field>
                      <md-datepicker v-model="system_update_freeze_end_date" md-immediately>
                        <label>End Date</label>
                      </md-datepicker>
                  </md-field>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-9">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-success" :disabled="isDisabled" @click="UpdateProfile()">Save</md-button>
              </div>
              <br />
              <div class="md-layout-item md-size-50">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-field>
                      <label>Location Mode</label>
                      <md-select v-model="location_mode" name="location_mode">
                        <md-option value="LOCATION_MODE_UNSPECIFIED">UNSPECIFIED</md-option>
                        <md-option value="HIGH_ACCURACY">HIGH ACCURACY</md-option>
                        <md-option value="SENSORS_ONLY">SENSORS ONLY</md-option>
                        <md-option value="BATTERY_SAVING">BATTERY SAVING</md-option>
                        <md-option value="OFF">OFF</md-option>
                        <md-option value="LOCATION_USER_CHOICE">LOCATION USER CHOICE</md-option>
                        <md-option value="LOCATION_ENFORCED">LOCATION ENFORCED</md-option>
                        <md-option value="LOCATION_DISABLED">LOCATION DISABLED</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-10">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-success" :disabled="isDisabled" @click="UpdateProfile()">Save</md-button>
              </div>
              <br />
              <div class="md-layout">
                <div class="md-layout-item">
                  <md-switch v-model="enable_knox_settings">Enable Knox</md-switch>
                  <small class="text-danger">By enablling this tab, Nova Knox plugin will installed to the device</small>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-card>
                      <md-card-header>
                        <h4 class="title"><b>Device Settings</b></h4>
                      </md-card-header>
                      <md-card-content>
                        <div class="md-layout-item">
                          <md-switch v-model="show_activity_log" :disabled="!this.enable_knox_settings">Show activity log</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="stop_incoming_call" :disabled="!this.enable_knox_settings">Stop incoming calls</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="stop_incoming_sms" :disabled="!this.enable_knox_settings">Stop incoming sms</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_multi_window_mode" :disabled="!this.enable_knox_settings">Disable multi window mode</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_firmware_update" :disabled="!this.enable_knox_settings">Disable firmware update</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="hide_status_bar" :disabled="!this.enable_knox_settings">Hide status bar</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_over_the_air" :disabled="!this.enable_knox_settings">Disable over the air</md-switch>
                        </div>
                      </md-card-content>
                    </md-card>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-card>
                      <md-card-header>
                        <h4 class="title">
                          <b>Hardware Keys Settings</b>
                        </h4>
                      </md-card-header>
                      <md-card-content>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_recent_key" :disabled="!this.enable_knox_settings">Disable recent key</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_home_key" :disabled="!this.enable_knox_settings">Disable home key</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_back_key" :disabled="!this.enable_knox_settings">Disable back key</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_power_button" :disabled="!this.enable_knox_settings">Disable power button</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_volumne_up_key" :disabled="!this.enable_knox_settings">Disable volume up key</md-switch>
                        </div>
                        <div class="md-layout-item">
                          <md-switch v-model="disable_volumne_down_key" :disabled="!this.enable_knox_settings">Disable volume down key</md-switch>
                        </div>
                      </md-card-content>
                    </md-card>
                  </div>
              </div>
              <h3>Other Settings</h3>
              <hr>
              <div class="md-layout">
                <label class="md-layout-item md-size-20 md-form-label">
                  APK List
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input type="text" v-model="apk_list" :disabled="!this.enable_knox_settings"></md-input>
                    <span class="md-helper-text">Pass comma seperated value like <b><span style="color:red;">https://www.tabnova.com/classroom.apk,https://www.tabnova.com/kidsdasboard.apk</span></b></span>
                  </md-field>
                </div>
              </div>
              <br class="mobile-break">
              <div class="md-layout">
                <label class="md-layout-item md-size-20 md-form-label">
                  Deactivate Apps List
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input type="text" v-model="deactivate_app_list" :disabled="!this.enable_knox_settings"></md-input>
                    <span class="md-helper-text">Pass comma seperated value like <b><span style="color:red;">com.packagename,com.tabnova.classroomapp</span></b></span>
                  </md-field>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </template>
        <template slot="tab-pane-11">
          <CreateQRCode :profileId="profile_id" />
        </template>
        <template slot="tab-pane-12">
          <ProfileAdvanceSettings :deviceDeleteInterval="deviceDeleteInterval" :profileId="profile_id" />
        </template>
      </tabs>
      <!-- Permission Modals -->
      <template>
            <modal v-if="permissionModal" @close="permissionModalHide">
              <template slot="header">
                <h4 class="modal-title">App Permissions</h4>
                <md-button
                  style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                  @click="permissionModalHide"><md-icon>clear</md-icon></md-button>
              </template>
              <template slot="body">
              <div class="box-body">
                <div class="m-hieght">
                  <div class="md-layout" v-for="item in applicationPermissionList" :key="item.permissionId">
                    <div class="md-layout-item md-size-50">
                      <md-field>
                        <label>Permission</label>
                        <md-input type="text" name="permissionId[]" :value="item.permissionId" readonly></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                      <md-field>
                        <select class="form-control" name="permissionAccess[]">
                          <option value="GRANT" v-bind:selected="item.permissionAccess == 'GRANT'">GRANT</option>
                          <option value="DENY" v-bind:selected="item.permissionAccess == 'DENY'">DENY</option>
                          <option value="PROMPT" v-bind:selected="item.permissionAccess == 'PROMPT'">PROMPT</option>
                        </select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
              </template>
              <template slot="footer">
                <md-button class="md-danger md-simple" @click="permissionModalHide">Close</md-button>
                <md-button class="md-success mt-1" type="submit" :disabled="isDisabled" @click="UpdateAppPermission()">Save</md-button>
              </template>
            </modal>
      </template>

      <!--Reset Password Modal -->
      <template>
            <modal v-if="ResetPasswordModal" @close="ResetPasswordModalHide">
              <template slot="header">
                <h4 class="modal-title">Reset Password</h4>
                <md-button
                  style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                  @click="ResetPasswordModalHide"><md-icon>clear</md-icon></md-button>
              </template>
              <template slot="body">
              <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>New Password</label>
                      <md-input type="password" v-model="reset_password_new"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              </template>
              <template slot="footer">
                <md-button class="md-danger md-simple" @click="ResetPasswordModalHide">Close</md-button>
                <md-button class="md-success mt-1" :disabled="isDisabled" @click="IssueCommand('RESET_PASSWORD')">
                  Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
              </template>
            </modal>
      </template>
      
      <!--Add Wifi Modal -->
      <template>
          <modal v-if="AddWifiModal" @close="AddWifiModalHide">
            <template slot="header">
              <h4 class="modal-title">Add WiFi</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="AddWifiModalHide"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>SSID Name</label>
                    <md-input type="text" v-model="wifi_ssid"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50"> 
                  <md-field>
                    <select v-model="wifi_security" class="browser-default custom-select" style="padding: 9px;border: none;">
                      <option value="None" selected>None</option>
                      <option value="WEP-PSK">WEP-PSK</option>
                      <option value="WPA-PSK">WPA-PSK</option>
                    </select>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Passphrase</label>
                    <md-input type="text" style="background-color:unset;" v-model="wifi_passphrase" :disabled="['None'].indexOf(wifi_security) !== -1"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field class="autoconnect">
                    <md-checkbox v-model="wifi_autoconnect">Auto Connect</md-checkbox>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="AddWifiModalHide">Close</md-button>
              <md-button class="md-success mt-1" :disabled="isDisabled" @click="AddWifi()">Save</md-button>
            </template>
          </modal>
      </template>
      

      <!-- Assign User Modal-->
      <template>
            <modal v-if="assignUserModal" @close="assignUserModalHide">
              <template slot="header">
                <h4 class="modal-title">Assign User</h4>
                <md-button
                  style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                  @click="assignUserModalHide"
                >
                  <md-icon>clear</md-icon>
                </md-button>
              </template>
              <template slot="body">
              
                <div class="m-hieght">
                  <md-table v-model="application_users" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Actions" style="text-align:left;">
                        <div class="md-layout">
                          <div class="md-layout-item">
                            <md-radio :value="item._id" v-model="device_user"></md-radio>
                          </div>
                        </div>
                      </md-table-cell>
                      <md-table-cell md-label="User Name" style="text-align:left;">{{ item.user_name}}</md-table-cell>
                      <md-table-cell md-label="Email" style="text-align:left;">{{ item.email}}</md-table-cell>
                      <md-table-cell md-label="First Name" style="text-align:left;">{{ item.first_name}}</md-table-cell>
                      <md-table-cell md-label="Last Name" style="text-align:left;">{{ item.last_name }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </template>
              <template slot="footer">
                <md-button class="md-danger md-simple" @click="assignUserModalHide"
                  >Close</md-button>
                <md-button class="md-success mt-1" type="submit" :disabled="isDisabled" @click="AssignDeviceUser()">Assign It</md-button>
              </template>
            </modal>
      </template>

      <!-- Move Device Modal-->
      <template>
            <modal v-if="MoveDeviceModal" @close="MoveDeviceModalHide">
              <template slot="header">
                <h4 class="modal-title">Move Device</h4>
                <md-button
                  style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                  @click="MoveDeviceModalHide"
                >
                  <md-icon>clear</md-icon>
                </md-button>
              </template>
              <template slot="body">
              <div class="box-body">
                <div class="m-hieght">
                  <md-table v-model="user_profiles" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Actions" style="text-align:left;">
                        <div class="md-layout">
                          <div class="md-layout-item">
                            <md-radio :value="item._id" v-model="new_device_profile"></md-radio>
                          </div>
                        </div>
                      </md-table-cell>
                      <md-table-cell md-label="Profile Name" style="text-align:left;">{{ item.profile_name}}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
              </template>
              <template slot="footer">
                <md-button class="md-danger md-simple" @click="MoveDeviceModalHide">Close</md-button>
                <md-button class="md-success mt-1" type="submit" :disabled="isDisabled" @click="MoveDeviceProfile()">Move It</md-button>
              </template>
            </modal>
      </template>

      <!-- Add Content User Modal -->
      <template>
          <modal v-if="AddContentUserModal" @close="AddContentUserModal = false">
            <template slot="header">
              <h4 class="modal-title">Add Content User</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="AddContentUserModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                     <select class="box-textbox" style="width: 100%;" placeholder="Search By User" v-model="add_content_user_id">
                          <option value="">Search By User</option>
                          <option v-for="user in application_users" v-bind:key="user.user_name" :value="user._id">
                            {{ user.user_name }}
                          </option>
                      </select>
                    </md-field>
                  </div>
                </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="AddContentUserModal = false">Close</md-button>
              <md-button class="md-success mt-1" type="submit" :disabled="isDisabled" @click="AddContentUser()">Save</md-button>
            </template>
          </modal>
      </template>

      <!--Upfate Profile Name Modal -->
      <template>
          <modal v-if="updateProfileNameModal" @close="updateProfileNameModal = false">
            <template slot="header">
              <h4 class="modal-title">Update Profile Name</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="updateProfileNameModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <label>Profile Name</label>
                    <md-input type="text" v-model="profile_name"></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="updateProfileNameModal = false">Close</md-button>
              <md-button class="md-success mt-1" type="submit" :disabled="isDisabled" @click="UpdateProfile()">Save</md-button>
            </template>
          </modal>
      </template>
    </div>
  </div>
</template>
<script>
import { Tabs } from "@/components";
import { Collapse } from "@/components";
import { Modal } from "@/components";
import DeviceProceDataService from "../../services/DeviceProceDataService";
import ApplicationDataService from "../../services/ApplicationDataService";
import ApplicationUserDataService from "../../services/ApplicationUserDataService";
import UserDataService from "../../services/UsersDataService";
import DashboardDataService from "../../services/DashboardDataService";
import Swal from "sweetalert2";
import $ from "jquery";
import ProfileAdvanceSettings  from '@/components/Custom/ProfileAdvanceSettings';
import CreateQRCode  from '@/components/Custom/CreateQRCode';

export default {
  components: {
    Tabs,
    Collapse,
    Modal,
    ProfileAdvanceSettings,
    CreateQRCode
  },
  props: {
    LoaderImage: {
      type: String,
      default: "./img/loader.gif",
    },
  },
  data() {
    return {
      profile_name: null,
      profile_id: this.$route.query.profile_id,
      isDisabled: false,
      isDeviceBtnDisabled: true,
      volume_key: 0,
      outgoing_calls_disabled: 0,
      sms_disabled: 0,
      bluetooth_disabled: 0,
      outgoing_beam_disabled: 0,
      add_user_disabled: 0,
      remove_user_disabled: 0,
      modify_accounts_disabled: 0,
      mobile_networks_config_disabled: 0,
      tethering_config_disabled: 0,
      wifi_config_disabled: 0,
      factoryResetDisabled: 0,
      screen_capture_disabled: 0,
      camera_disabled: 0,
      keyguard_disabled: 0,
      status_bar_disabled: 0,
      adjust_volume_disabled: 0,
      install_unknown_sources_allowed: 0,
      safe_boot_disabled: 0,
      package_name: null,
      applications: [],
      create_application_package_name: null,
      profileApplications: null,
      profileApplicationsKiosk: [],
      profileDevices: null,
      developer_settings: null,
      
      // Password Policy
      passwordQuality: null,
      passwordMinimumLength: 4,
      passwordMinimumSymbols: 0,
      passwordMinimumLowerCase: 0,
      passwordMinimumLetters: 0,
      passwordMinimumUpperCase: 0,
      passwordMinimumNumeric: 0,
      passwordHistoryLength: 0,
      maximumFailedPasswordsForWipe: 0,
      passwordExpirationTimeout: 0,
      maximumTimeToLock: 0,

      // Devices
      selected_devices: [],
      assignUserModal: false,
      device_user_name: "",
      application_users: [],
      device_user: null,
      ResetPasswordModal: false,
      reset_password_new: null,
      MoveDeviceModal: false,
      user_profiles: [],
      new_device_profile: null,
      isExitKioskDisabled: false,
      disabledFactoryReset: false,
      factoryResetText: "Factory Reset",
      search_only_deleted_device: false,

      // Application
      permissionModal: false,
      applicationPermissionList: [],
      permission_package_name: null,
      search_package_name: "",
      storeApps: [],
      
      // Application Config Variable
      profile_content_path: null,

      // Kiosk
      kiosk_power_button_action:"POWER_BUTTON_ACTIONS_UNSPECIFIED",
      kiosk_system_app_warning:"SYSTEM_ERROR_WARNINGS_UNSPECIFIED",
      kiosk_system_navigation:"SYSTEM_NAVIGATION_UNSPECIFIED",
      kiosk_status_bar:"STATUS_BAR_UNSPECIFIED",
      kiosk_device_setting:"SETTINGS_ACCESS_ALLOWED",
      kiosk_type: "singleApp",

      // Multi App Kiosk
      multi_app_kiosk_google_custom_launcher: true,

      // Browser kiosk
      browser_kiosk_screen_timeout: "0",
      browser_kiosk_screen_brightness: "0",
      browser_kiosk_screen_orientation: "Auto",
      browser_kiosk_website_url: "",
      browser_kiosk_close_on_touch: false,
      browser_kiosk_enable_screen_saver: false,
      browser_kiosk_show_address_bar: false,

      // Signage Kiosk
      signage_kiosk_screen_timeout: "0",
      signage_kiosk_screen_brightness: "0",
      signage_kiosk_screen_orientation: "Auto",
      signage_kiosk_close_on_touch: false,
      textFields: [],
      signage_videos: [],

      //Wifi
      profileWifi: [],
      AddWifiModal: false,
      wifi_ssid: null,
      wifi_security: "None",
      wifi_passphrase: null,
      wifi_autoconnect: false,

      //System Update
      system_update_type: "SYSTEM_UPDATE_TYPE_UNSPECIFIED",
      system_update_start_time: null,
      system_update_end_time: null,
      system_update_freeze_start_date: null,
      system_update_freeze_end_date: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },

      //Location Mode
      location_mode: "LOCATION_MODE_UNSPECIFIED",

      //Samsung Device
      enable_knox_settings: false,
      show_activity_log: false,
      stop_incoming_call: false,
      stop_incoming_sms: false,
      disable_multi_window_mode: false,
      disable_firmware_update: false,
      hide_status_bar: false,
      disable_over_the_air: false,
      disable_recent_key: false,
      disable_home_key: false,
      disable_back_key: false,
      disable_power_button: false,
      disable_volumne_up_key: false,
      disable_volumne_down_key: false,
      apk_list: null,
      deactivate_app_list: null,

      // Content Tab
      cloud_storage_created: false,
      contentFile: "",
      profileContent: [],
      uploadPercentage: 0,
      content_type: "Upload",
      content_id: null,
      globalContent: [],
      update_content_id: null,
      AddContentUserModal: false,
      add_content_user_id: "",
      storage_used: 0,
      storage_available: 0,
      storage_total: 0,
      note: "",

      updateProfileNameModal: false,

      // Advance Setting
      deviceDeleteInterval: "",
      enableProfileDisappearing:false,

      enableFullPlayStore: false,
    };
  },
  computed: {
    noWebApp: function () {
      return this.profileApplications.filter(i => i.is_web_app == false)
    },
    webApp: function () {
      return this.profileApplications.filter(i => i.is_web_app == true)
    }
  },
  methods: {
    GetDeviceProfileDetail() {
      DeviceProceDataService.GetDeviceProfileDetail(
        this.$route.query.profile_id
      )
        .then((response) => {
          if (response.data.data) {
            this.profile_name = response.data.data.profile_name;
            this.outgoing_calls_disabled = response.data.data.outgoing_calls_disabled;
            this.sms_disabled = response.data.data.sms_disabled;
            this.enableFullPlayStore = response.data.data.enableFullPlayStore;
            this.bluetooth_disabled = response.data.data.bluetooth_disabled;
            this.outgoing_beam_disabled = response.data.data.outgoing_beam_disabled;
            this.add_user_disabled = response.data.data.add_user_disabled;
            this.remove_user_disabled = response.data.data.remove_user_disabled;
            this.modify_accounts_disabled = response.data.data.modify_accounts_disabled;
            this.mobile_networks_config_disabled = response.data.data.mobile_networks_config_disabled;
            this.tethering_config_disabled = response.data.data.tethering_config_disabled;
            this.wifi_config_disabled = response.data.data.wifi_config_disabled;
            this.factoryResetDisabled = response.data.data.factoryResetDisabled;
            this.screen_capture_disabled = response.data.data.screen_capture_disabled;
            this.camera_disabled = response.data.data.camera_disabled;
            this.keyguard_disabled = response.data.data.keyguard_disabled;
            this.status_bar_disabled = response.data.data.status_bar_disabled;
            this.adjust_volume_disabled = response.data.data.adjust_volume_disabled;
            this.package_name = response.data.data.single_app_kiosk_package_name;
            this.developer_settings = response.data.data.developer_settings;
            this.install_unknown_sources_allowed = response.data.data.install_unknown_sources_allowed;
            this.safe_boot_disabled = response.data.data.safe_boot_disabled;

            // Password Policy
            this.passwordQuality = response.data.data.password_type;
            this.passwordMinimumLength = response.data.data.min_password_length;
            this.passwordMinimumSymbols = response.data.data.min_number_of_symbols;
            this.passwordMinimumLowerCase = response.data.data.min_number_of_lower_case_characters;
            this.passwordMinimumLetters = response.data.data.min_number_of_alphabets;
            this.passwordMinimumUpperCase = response.data.data.min_number_of_upper_case_characters;
            this.passwordMinimumNumeric = response.data.data.min_number_of_digits;
            this.passwordHistoryLength = response.data.data.max_password_history_list;
            this.maximumFailedPasswordsForWipe = response.data.data.max_failed_attempt_to_factory_reset;
            this.passwordExpirationTimeout = response.data.data.password_expiry_period;
            this.maximumTimeToLock = response.data.data.idle_time_for_auto_lock;

            // Application Config Variable
            this.profile_content_path = response.data.data.profile_content_path;

            // Kiosk Setting
            this.kiosk_type = response.data.data.kiosk_type;
            this.kiosk_power_button_action = response.data.data.kiosk_power_button_action;
            this.kiosk_system_app_warning = response.data.data.kiosk_system_app_warning;
            this.kiosk_system_navigation = response.data.data.kiosk_system_navigation;
            this.kiosk_status_bar = response.data.data.kiosk_status_bar;
            this.kiosk_device_setting = response.data.data.kiosk_device_setting;

            // Multi App Kiosk
            this.multi_app_kiosk_google_custom_launcher = response.data.data.multi_app_kiosk_google_custom_launcher;

            //Browser kiosk
            if (response.data.data.browser_kiosk) {
              this.browser_kiosk_screen_timeout = response.data.data.browser_kiosk.browser_kiosk_screen_timeout;
              this.browser_kiosk_screen_brightness = response.data.data.browser_kiosk.browser_kiosk_screen_brightness;
              this.browser_kiosk_screen_orientation = response.data.data.browser_kiosk.browser_kiosk_screen_orientation;
              this.browser_kiosk_website_url = response.data.data.browser_kiosk.browser_kiosk_website_url;
              this.browser_kiosk_close_on_touch = response.data.data.browser_kiosk.browser_kiosk_close_on_touch;
              this.browser_kiosk_enable_screen_saver = response.data.data.browser_kiosk.browser_kiosk_enable_screen_saver;
              this.browser_kiosk_show_address_bar = response.data.data.browser_kiosk.browser_kiosk_show_address_bar;
            }
            
            //Signage kiosk
            if (response.data.data.signage_kiosk) {
              this.signage_kiosk_screen_timeout = response.data.data.signage_kiosk.signage_kiosk_screen_timeout;
              this.signage_kiosk_screen_brightness = response.data.data.signage_kiosk.signage_kiosk_screen_brightness;
              this.signage_kiosk_screen_orientation = response.data.data.signage_kiosk.signage_kiosk_screen_orientation;
              this.signage_kiosk_close_on_touch = response.data.data.signage_kiosk.signage_kiosk_close_on_touch;
              if (response.data.data.signage_kiosk.video_lists.length > 0) {
                this.signage_videos = response.data.data.signage_kiosk.video_lists;
              }
              /*if (response.data.data.signage_kiosk.video_lists.length > 0) {
                response.data.data.signage_kiosk.video_lists.forEach(video_url => {
                  this.textFields.push({ 
                    label1: "Video Url", 
                    value1: video_url,
                  });
                })
              }*/
            }
            // System Update
            this.system_update_type = response.data.data.system_update_type;
            this.system_update_start_time = response.data.data.system_update_start_time;
            this.system_update_end_time = response.data.data.system_update_end_time;
            this.system_update_freeze_start_date = (response.data.data.system_update_freeze_start_date) ? new Date(response.data.data.system_update_freeze_start_date) : null;
            this.system_update_freeze_end_date = (response.data.data.system_update_freeze_end_date) ? new Date(response.data.data.system_update_freeze_end_date) : null;

            // Location Mode 
            this.location_mode = response.data.data.location_mode;

            // Samsung Device Settings
            if (response.data.data.samsung_device_settings) {
              this.enable_knox_settings = response.data.data.enable_knox_settings;
              this.show_activity_log = response.data.data.samsung_device_settings.show_activity_log;
              this.stop_incoming_call = response.data.data.samsung_device_settings.stop_incoming_call;
              this.stop_incoming_sms = response.data.data.samsung_device_settings.stop_incoming_sms;
              this.disable_multi_window_mode = response.data.data.samsung_device_settings.disable_multi_window_mode;
              this.disable_firmware_update = response.data.data.samsung_device_settings.disable_firmware_update;
              this.hide_status_bar = response.data.data.samsung_device_settings.hide_status_bar;
              this.disable_over_the_air = response.data.data.samsung_device_settings.disable_over_the_air;
              this.disable_recent_key = response.data.data.samsung_device_settings.disable_recent_key;
              this.disable_home_key = response.data.data.samsung_device_settings.disable_home_key;
              this.disable_back_key = response.data.data.samsung_device_settings.disable_back_key;
              this.disable_power_button = response.data.data.samsung_device_settings.disable_power_button;
              this.disable_volumne_up_key = response.data.data.samsung_device_settings.disable_volumne_up_key;
              this.disable_volumne_down_key = response.data.data.samsung_device_settings.disable_volumne_down_key;
              this.apk_list= response.data.data.samsung_device_settings.apk_list;
              this.deactivate_app_list= response.data.data.samsung_device_settings.deactivate_app_list;
            }

            this.deviceDeleteInterval = response.data.data.deviceDeleteInterval;
            this.enableProfileDisappearing = response.data.data.enableProfileDisappearing;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    UpdateProfile(installType = "", default_package_name = null, auto_update_mode = null, external_trigger = false) {
      if (this.kiosk_type != "singleApp" && external_trigger == false) {
        installType = "EXIT_KIOSK"
      }
      let package_name = default_package_name ? default_package_name : this.package_name;
      this.isDisabled = true;
      var data = {
        profile_id: this.$route.query.profile_id,
        profile_name: this.profile_name,
        outgoing_calls_disabled: this.outgoing_calls_disabled,
        sms_disabled: this.sms_disabled,
        bluetooth_disabled: this.bluetooth_disabled,
        outgoing_beam_disabled: this.outgoing_beam_disabled,
        add_user_disabled: this.add_user_disabled,
        remove_user_disabled: this.remove_user_disabled,
        modify_accounts_disabled: this.modify_accounts_disabled,
        mobile_networks_config_disabled: this.mobile_networks_config_disabled,
        tethering_config_disabled: this.tethering_config_disabled,
        wifi_config_disabled: this.wifi_config_disabled,
        factoryResetDisabled: this.factoryResetDisabled,
        screen_capture_disabled: this.screen_capture_disabled,
        camera_disabled: this.camera_disabled,
        keyguard_disabled: this.keyguard_disabled,
        status_bar_disabled: this.status_bar_disabled,
        adjust_volume_disabled: this.adjust_volume_disabled,
        package_name: package_name,
        install_type: installType,
        developer_settings: this.developer_settings,
        install_unknown_sources_allowed: this.install_unknown_sources_allowed,
        safe_boot_disabled: this.safe_boot_disabled,
        enableFullPlayStore: this.enableFullPlayStore,

        // Password Policy
        password_type: this.passwordQuality,
        min_password_length: this.passwordMinimumLength,
        min_number_of_symbols: this.passwordMinimumSymbols,
        min_number_of_alphabets: this.passwordMinimumLetters,
        min_number_of_lower_case_characters: this.passwordMinimumLowerCase,
        min_number_of_upper_case_characters: this.passwordMinimumUpperCase,
        min_number_of_digits: this.passwordMinimumNumeric,
        max_password_history_list: this.passwordHistoryLength,
        max_failed_attempt_to_factory_reset: this.maximumFailedPasswordsForWipe,
        password_expiry_period: this.passwordExpirationTimeout,
        idle_time_for_auto_lock: this.maximumTimeToLock,

        // Application Config Variable
        profile_content_path: this.profile_content_path,
        auto_update_mode: auto_update_mode,

        //Kiosk Setting
        kiosk_type: this.kiosk_type,
        kiosk_power_button_action: this.kiosk_power_button_action,
        kiosk_system_app_warning: this.kiosk_system_app_warning,
        kiosk_system_navigation: this.kiosk_system_navigation,
        kiosk_status_bar: this.kiosk_status_bar,
        kiosk_device_setting: this.kiosk_device_setting,

        //System Update
        system_update_type: this.system_update_type,
        system_update_freeze_start_date: this.system_update_freeze_start_date,
        system_update_freeze_end_date: this.system_update_freeze_end_date,

        //System Update
        location_mode: this.location_mode,

        //Samsung Device Settings
        enable_knox_settings: this.enable_knox_settings,
        show_activity_log: this.show_activity_log,
        stop_incoming_call: this.stop_incoming_call,
        stop_incoming_sms: this.stop_incoming_sms,
        disable_multi_window_mode: this.disable_multi_window_mode,
        disable_firmware_update: this.disable_firmware_update,
        hide_status_bar: this.hide_status_bar,
        disable_over_the_air: this.disable_over_the_air,
        disable_recent_key: this.disable_recent_key,
        disable_home_key: this.disable_home_key,
        disable_back_key: this.disable_back_key,
        disable_power_button: this.disable_power_button,
        disable_volumne_up_key: this.disable_volumne_up_key,
        disable_volumne_down_key: this.disable_volumne_down_key,
        apk_list: this.apk_list,
        deactivate_app_list: this.deactivate_app_list,

      };
      if (this.system_update_type == "WINDOWED") {
        data.system_update_start_time= this.system_update_start_time;
        data.system_update_end_time= this.system_update_end_time;
      }
      if (this.kiosk_type == "MULTI APP") {
          data.multi_app_kiosk_google_custom_launcher= this.multi_app_kiosk_google_custom_launcher;
      }
      if (this.kiosk_type == "BROWSER") {
        data.browser_kiosk_screen_timeout= this.browser_kiosk_screen_timeout;
        data.browser_kiosk_screen_brightness= this.browser_kiosk_screen_brightness;
        data.browser_kiosk_screen_orientation= this.browser_kiosk_screen_orientation;
        data.browser_kiosk_website_url= this.browser_kiosk_website_url;
        data.browser_kiosk_close_on_touch= this.browser_kiosk_close_on_touch;
        data.browser_kiosk_enable_screen_saver= this.browser_kiosk_enable_screen_saver;
        data.browser_kiosk_show_address_bar= this.browser_kiosk_show_address_bar;
      }
      if (this.kiosk_type == "SIGNAGE") {
        data.signage_kiosk_screen_timeout= this.signage_kiosk_screen_timeout;
        data.signage_kiosk_screen_brightness= this.signage_kiosk_screen_brightness;
        data.signage_kiosk_screen_orientation= this.signage_kiosk_screen_orientation;
        data.signage_kiosk_close_on_touch= this.signage_kiosk_close_on_touch;
        var video_lists = $("input[name='video_list[]']")
              .map(function(){
                  if($(this).val()) {
                    return $(this).val();
                  }
                }
              ).get();
        data.video_lists= this.signage_videos;
      }

      DeviceProceDataService.UpdateProfile(data)
        .then((response) => {
          this.isDisabled = false;
          if (installType == "EXIT_KIOSK") {
            this.package_name = null;
          }
          this.updateProfileNameModal = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    ApplyPolicy(profile_id = "") {
      this.isDisabled = true;
      if (profile_id) {
        var data = {
          profile_id: profile_id,
        };
      } else {
        var data = {
          profile_id: this.$route.query.profile_id,
        };
      }

      DeviceProceDataService.ApplyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.data.payment_redirection) {
            this.$router.push({ path: 'plan-list'})
          }
        });
    },

    ExitKioskMode() {
      this.UpdateProfile("EXIT_KIOSK");
    },

    GetApplicationList() {
      this.applications = [];
      ApplicationDataService.GetApplicationList(this.profile_id,"Android","",false,this.search_package_name)
        .then((response) => {
          if (response.data.data) {
            this.applications = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });

        if (this.search_package_name) {
           ApplicationDataService.SearchPlayStoreApp(this.search_package_name, "us")
            .then((response) => {
              this.storeApps = response.data.data;
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            });    
        } else {
          this.storeApps = [];
        }
    },

    GetProfileApplicationList() {
      this.profileApplications = [];
      DeviceProceDataService.GetProfileApplicationList(
        this.$route.query.profile_id
      )
        .then((response) => {
          if (response.data.data) {
            this.profileApplications = response.data.data;
            this.profileApplicationsKiosk = response.data.data.filter(i => i.is_app_disabled == false);
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    KioskTypeChange() {
      if (this.profileApplications.length == 1) {
        if (this.kiosk_type == "singleApp") {   
            this.package_name = this.profileApplications[0].package_name;
        } else {
            this.package_name = "";
        }
      }
    },

    GetProfileDeviceList() {
      this.profileDevices = [];
      $(".loader-img").show();
      let data = {
        profile_id: this.$route.query.profile_id,
        serial_number: "",
        user_id: "",
        hide_assign_devices: false,
        type: "",
        imei_number: "",
        model: "",
        only_deleted_devices: this.search_only_deleted_device
      }
      DeviceProceDataService.GetProfileDeviceList(data)
        .then((response) => {
          if (response.data.data) {
            $(".loader-img").hide();
            this.profileDevices = response.data.data;
          }
          this.disabledFactoryReset = response.data.disabledFactoryReset;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    RefreshProfileDevice() {
      this.isDisabled = true;

      DeviceProceDataService.RefreshProfileDevice(this.profile_id)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetProfileDeviceList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    async CreateProfileApplication(package_name, is_web_app, app = "") {
      if (app) {
        let data = {
            product_id: app.appId,
            package_name: app.appId,
            app_icon: app.icon,
            application_details: app,
            type: "Android"
        };
        await ApplicationDataService.CreateIosApplication(data)
        .then((response) => {
            let updated_app_store_application = this.storeApps.filter(elem => elem.appId != app.appId)
            this.storeApps = updated_app_store_application;
            this.search_package_name = "";
        }).catch((e) => {
          let updated_app_store_application = this.storeApps.filter(elem => elem.appId != app.appId)
          this.storeApps = updated_app_store_application;
          this.search_package_name = "";
        })
      }

      var data = {
        profile_id: this.$route.query.profile_id,
        package_name: package_name,
        is_web_app: is_web_app,
      };

      DeviceProceDataService.CreateProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.GetProfileApplicationList();
          this.GetApplicationList();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    DeleteProfileApplication(package_name) {
      var data = {
        package_name: package_name,
        profile_id: this.$route.query.profile_id,
      };

      DeviceProceDataService.DeleteProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.GetProfileApplicationList();
          this.GetApplicationList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    InstallApplication(package_name) {
      this.UpdateProfile("FORCE_INSTALLED", package_name);
    },

    UnInstallApplication(package_name) {
      this.UpdateProfile("BLOCKED", package_name);
    },

    ChangeInstallType(package_name, event) {
      this.UpdateProfile(event.target.value, package_name,null, true);
      //alert(event.target.value)
    },

    ChangeAutoUpdateMode(package_name, event) {
      this.UpdateProfile("", package_name, event.target.value, true);
    },

    select(managementMode) {
			if (this.selected_devices.length > 0) {
        if (managementMode == "PROFILE_OWNER") {
          this.factoryResetText = "Remove";
        } else {
          this.factoryResetText = "Factory Reset";  
        }
        this.isDeviceBtnDisabled = false;
      } else {
        this.isDeviceBtnDisabled = true;
        this.factoryResetText = "Factory Reset";
      }
		},

    IssueCommand(command) {
      this.isDeviceBtnDisabled = true;
      this.isDisabled = true;
      this.selected_devices.forEach((value, index) => {
        if (command == "RESET_PASSWORD") {
          var data = {
            "type": command,
            "name": value,
            "newPassword": this.reset_password_new,
          } 
        } else {
          var data = {
            "type": command,
            "name": value,
          } 
        }
        if (command == "REBOOT") {
            Swal.fire({
            title: "Are you sure?",
            html: "<ul><li>Only company owned device will be rebooted.</li></ul>",
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, Reboot It!",
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              DeviceProceDataService.IssueCommand(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.isDeviceBtnDisabled = false;
                this.isDisabled = false;
                this.ResetPasswordModal = false;
                this.selected_devices = [];
              })
              .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
                this.isDeviceBtnDisabled = false;
                this.isDisabled = false;
                this.selected_devices = [];
              });
            } else {
              this.isDeviceBtnDisabled = false;
              this.isDisabled = false;
              this.selected_devices = [];
            }
          });
        } else {
          DeviceProceDataService.IssueCommand(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.isDeviceBtnDisabled = false;
            this.isDisabled = false;
            this.ResetPasswordModal = false;
            this.selected_devices = [];
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDeviceBtnDisabled = false;
            this.isDisabled = false;
            this.selected_devices = [];
          });
        }
      });
    },

    exitDeviceKiosk() {
      this.isExitKioskDisabled = true;
      this.isDisabled = true;
      this.selected_devices.forEach((value, index) => {
        let data = {
            name: value,
            profile_id: this.profile_id
        }
        DeviceProceDataService.exitDeviceKiosk(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.isExitKioskDisabled = false;
          this.isDisabled = false;
          this.selected_devices = [];
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isExitKioskDisabled = false;
          this.isDisabled = false;
          this.selected_devices = [];
        });
      });
    },

    DeleteDevice() {
      Swal.fire({
        title: "Are you sure?",
        html: '<ul><li>Work profile will be removed from the BYOD devices.</li><li>Company owned device will be factory reset.</li></ul>',
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isDeviceBtnDisabled = false;
          this.selected_devices.forEach((value, index) => {
            var data = {
              "name": value,
            } 
            DeviceProceDataService.DeleteDevice(data)
            .then((response) => {
              this.$toast.success(response.data.respones_msg);
              this.isDeviceBtnDisabled = true;
              this.selected_devices = [];
              this.GetProfileDeviceList();
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
              this.selected_devices = [];
              this.isDeviceBtnDisabled = true;
              this.GetProfileDeviceList();
            });
          });
        }
      });
    },

    GetApplicationPermission(package_name) {
      this.isDisabled = true;
      var data = {
        "package_name": package_name,
        "profile_id": this.profile_id
      }
      ApplicationDataService.GetApplicationPermission(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.isDisabled = false;
          this.applicationPermissionList = response.data.data;
          this.permissionModal =true;
          this.permission_package_name = package_name;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
        });
    },

    UpdateAppPermission() {
      var permissionAccess = document.getElementsByName('permissionAccess[]');
      var permissionId = document.getElementsByName('permissionId[]');
      var permissions = [];
      for (var i = 0; i < permissionAccess.length; i++) {
          var permission_access=permissionAccess[i];
          var permission_id=permissionId[i];
          let obj = {
            "permissionId": permission_id.value,
            "permissionAccess": permission_access.value
          };
          permissions.push(obj);
      }

      var data = {
        "permissions": permissions,
        "package_name": this.permission_package_name,
        "profile_id": this.profile_id
      }
      ApplicationDataService.UpdateAppPermission(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.isDisabled = false;
          this.permissionModal =false;
          this.permission_package_name = null;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
        });
    },
    
    permissionModalHide: function () {
      this.permissionModal = false;
    },

    assignUserModalHide: function () {
      this.assignUserModal = false;
    },

    ResetPasswordModalHide: function () {
      this.ResetPasswordModal = false;
    },

    AddWifiModalHide: function () {
      this.AddWifiModal = false;
    },

    MoveDeviceModalHide: function () {
      this.MoveDeviceModal = false;
    },

    GetApplicationUsers: function (open_modal = true) {
      ApplicationUserDataService.GetApplicationUserList()
        .then((response) => {
          if (response.data.data) {
            this.application_users = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
      if (open_modal) {
        this.assignUserModal = true;
      }
    },

    GetUserDevicePrrofiles: function () {
      let data = {
        profile_id: this.profile_id,
        search_profile_name: "",
        search_profile_type: "",
      }
      DeviceProceDataService.GetDeviceProfileList(data)
        .then((response) => {
          if (response.data.data) {
            this.user_profiles = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
        this.MoveDeviceModal = true;
    },

    AssignDeviceUser: function () {
      this.isDisabled = true;
      var data = {
        "devices": this.selected_devices,
        "user_id": this.device_user,
      } 
      DeviceProceDataService.AssignDeviceUser(data)
      .then((response) => {
        this.$toast.success(response.data.respones_msg);
        this.isDisabled = false;
        this.assignUserModalHide();
        this.selected_devices = [];
        this.device_user = null;
        this.GetProfileDeviceList();
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
        this.isDisabled = false;
      });
    },

    MoveDeviceProfile: function () {
      this.isDisabled = true;
      var data = {
        "devices": this.selected_devices,
        "new_profile_id": this.new_device_profile,
      } 
      DeviceProceDataService.MoveDeviceProfile(data)
      .then((response) => {
        this.$toast.success(response.data.respones_msg);
        this.isDisabled = false;
        this.MoveDeviceModalHide();
        this.ApplyPolicy(this.new_device_profile);
        this.selected_devices = [];
        this.new_profile_id = null;
        this.GetProfileDeviceList();
      })
      .catch((e) => {
        this.MoveDeviceModalHide();
        this.$toast.error(e.response.data.respones_msg);
        this.isDisabled = false;
      });
    },

    AddWifi: function () {
      this.isDisabled = true;
      var data = {
        "profile_id": this.profile_id,
        "wifi_ssid": this.wifi_ssid,
        "wifi_security": this.wifi_security,
        "wifi_passphrase": this.wifi_passphrase,
        "wifi_autoconnect": this.wifi_autoconnect,
      } 
      DeviceProceDataService.AddWifi(data)
      .then((response) => {
        this.wifi_ssid = null;
        this.wifi_security = null;
        this.wifi_passphrase = null;
        this.wifi_autoconnect = null;
        this.AddWifiModalHide();
        this.ListWifi();
        this.isDisabled = false;
        this.$toast.success(response.data.respones_msg);
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
        this.isDisabled = false;
      });
    },

    ListWifi: function () {
      DeviceProceDataService.ListWifi(this.$route.query.profile_id)
      .then((response) => {
        this.profileWifi = response.data.data;
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
        this.isDisabled = false;
      });
    },

    DeleteProfileWiFi: function (id) {

      Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            id : id
          };
          DeviceProceDataService.DeleteProfileWiFi(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.ListWifi();
            this.isDisabled = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
        }
      });
    },

    UpdateAppEnableDisableStatus: function (id) {
      let value = $("#"+id).is(":checked")
      let data = {
        "application_id": id,
        "is_app_disabled": value,
        profile_id: this.profile_id,
      }
      DeviceProceDataService.UpdateAppEnableDisableStatus(data)
      .then((response) => {
        this.$toast.success(response.data.respones_msg);
        this.isDisabled = false;
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
        this.isDisabled = false;
      });
    },

    addTextBox () {
        this.textFields.push({ 
          label1: "Video Url", 
          value1: "",
        })
     },
    
     deleteTextBox (index) {
         this.textFields.splice(index, 1)
     },

     GetLoginUserDetail() {
        var data = {};
        UserDataService.getUserDetail(data)
          .then((response) => {
            this.cloud_storage_created = response.data.data.next_cloud_account_created;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },

      CreateNextCloudUser() {
        this.isDisabled = true;
        DashboardDataService.CreateNextCloudUser()
        .then((response) => {
          this.isDisabled = false;
          this.cloud_storage_created = true;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });

      },

      handleFileUpload(event) {
        this.contentFile = event.target.files[0];
      },

      UploadContent() {
        this.isDisabled =true;
        $(".l-image").show();
        const config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
          }.bind(this)
        }
        let formData = new FormData();
        formData.append("fileData", this.contentFile);
        formData.append("profile_id", this.profile_id);
        formData.append("content_type", this.content_type);
        DeviceProceDataService.UploadContent(formData, config)
          .then((response) => {
            this.isDisabled = false;
            $(".l-image").hide();
            this.contentFile = "";
            this.$toast.success(response.data.respones_msg);
            this.ListContent();
            this.GetContentPublicLink(response.data.data);
          })
          .catch((e) => {
            $(".l-image").hide();
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
      },

      GetContentPublicLink(content_id) {
        
        let data = {
          "content_id": content_id,
        }
        DeviceProceDataService.GetContentPublicLink(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.ListContent();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      ListContent: function () {
        DeviceProceDataService.ListContent(this.$route.query.profile_id)
        .then((response) => {
          this.profileContent = response.data.data;
          this.globalContent = response.data.global_content;
          this.storage_used = response.data.storage_info.used;
          this.storage_total = response.data.storage_info.total;
          this.storage_available = response.data.storage_info.available;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
        });
      },

      DeleteProfileContent: function (id, content_type) {
        if (content_type == "Upload") {
            DeviceProceDataService.ListChildContent(id)
            .then((response) => {
                if (response.data.data.length > 0) {
                  let str = 'This content is used in another profiles too, If you want to delete it from another profile then please select below profile, <ul>';
                  for (let content of response.data.data) {
                    str += '<li><input type="checkbox" class="content-profile-chk-box" value="'+content._id+'">'+content.profile_id.profile_name+'</li>';
                  }
                  str += "</ul>";
        
                    Swal.fire({
                      title: "Are you sure?",
                      html:str,
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "md-button md-success",
                      cancelButtonClass: "md-button md-danger",
                      confirmButtonText: "Yes, delete it!",
                      buttonsStyling: false,
                    }).then((result) => {
                      if (result.value) {
                        let content_ids = [];
                        content_ids.push(id);
                        $(".content-profile-chk-box:checked").each(function() {
                            content_ids.push(this.value)
                        });
                        let data = {
                          content_ids : content_ids,
                          global_content_id: id,
                        };
                        DeviceProceDataService.DeleteProfileGlobalContent(data)
                        .then((response) => {
                          this.$toast.success(response.data.respones_msg);
                          this.ListContent();
                        })
                        .catch((e) => {
                          this.ListContent();
                          this.$toast.error(e.response.data.respones_msg);
                        });
                      }
                    });
                } else {
                  this.DeleteSingleContent(id, content_type);
                }
            }).catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
              this.isDisabled = false;
            });
        } else {
          this.DeleteSingleContent(id, content_type);
        }
      },

      DeleteSingleContent(id, content_type) {
        Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              let data = {
                content_id : id,
                content_type: content_type,
              };
              DeviceProceDataService.DeleteProfileContent(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.ListContent();
              })
              .catch((e) => {
                this.ListContent();
                this.$toast.error(e.response.data.respones_msg);
              });
            }
          });
      },

      ManuallyAddContent() {
        
        let data = {
          "content_type": this.content_type,
          "content_link": this.content_link,
          "profile_id": this.profile_id
        }

        DeviceProceDataService.ManuallyAddContent(data)
          .then((response) => {
            this.content_link= "";
            this.$toast.success(response.data.respones_msg);
            this.ListContent();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },

      GlobalAddContent() {
        
        let data = {
          "content_id": this.content_id,
          "profile_id": this.profile_id
        }

        DeviceProceDataService.GlobalAddContent(data)
          .then((response) => {
            this.content_id= "";
            this.$toast.success(response.data.respones_msg);
            this.ListContent();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },

      OpenAddContentUserPopup(content_id) {
        this.update_content_id = content_id;
        this.AddContentUserModal = true;
      },

      AddContentUser() {
        if (!this.update_content_id) {
            this.$toast.error("Error occur please try again.");
            this.AddContentUserModal = false;
            return;
        }
        if (!this.add_content_user_id) {
            this.$toast.error("Please select user.");
            return;
        }
        this.isDisabled = true;
        let data = {
          user_id: this.add_content_user_id,
          content_id: this.update_content_id
        }
        DeviceProceDataService.AddContentUser(data)
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.update_content_id = null;
            this.add_content_user_id = "";
            this.AddContentUserModal = false;
            this.ListContent();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.update_content_id = null,
            this.$toast.error(e.response.data.respones_msg);
          });
      },

      DeleteContentUser(content_id) {
        Swal.fire({
          title: "Are you sure?",
          text: `User will be unlink from this content`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var data = {
              content_id : content_id
            };
            DeviceProceDataService.DeleteContentUser(data)
            .then((response) => {
              this.$toast.success(response.data.respones_msg);
              this.ListContent();
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            });
          }
        });  
      },

      formatSizeUnits(stat, decimals = 2) {
        if (stat) {
          let bytes = stat;
          const k = 1024
          const dm = decimals < 0 ? 0 : decimals
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

          const i = Math.floor(Math.log(bytes) / Math.log(k))

          return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        } else {
          return "-";
        }
      },
      CopyProfileContent(content) {
        navigator.clipboard.writeText(content);
        this.$toast.success("text copied");
      },

      resizeWindow() {
        if ($(window).width() < 600) {
          $('.kiosk-div').removeClass('md-size-70').addClass('md-size-100');
          $('.kiosk-common-setting').removeClass('md-size-30').addClass('md-size-100');
        }
      },

      openFullscreen(id) {
        let elem = document.getElementById(id);
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      },

      UpdateProfileContentNote(id) {
        let data = {
          content_id: id,
          note: $("#note_"+id).val()
        }

        DeviceProceDataService.UpdateProfileContentNote(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      moveTo() {
        $('#5').trigger('click');
      }
  },
  beforeMount() {
    this.GetDeviceProfileDetail();
    this.GetApplicationList();
    this.GetProfileApplicationList();
  },
  mounted: function () {
    this.$nextTick(() => {
      const that = this;
      $("#0").on("click", function() {
        that.GetProfileApplicationList();
      });
      $("#2").on("click", function() {
        that.resizeWindow();
        that.ListContent();
      });
      $("#3").on("click", function() {
        that.GetProfileDeviceList();
      });
      $("#6").on("click", function() {
        that.ListWifi();
      });
      $("#5").on("click", function() {
        that.GetLoginUserDetail();
        that.ListContent();
        that.GetApplicationUsers(false);
      });
      /*$("#10").on("click", function() {
        that.GetQrCodeList();
      });*/
    })
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
};
</script>
<style>
/*.md-card-header {
  border-bottom: 1px solid black;
  padding: 5px !important; 
}*/
.tab-content {
  display: unset !important;
}
input[type="text"]:disabled {
    background-color: #dddddd;
}
.modal {
  min-height:400px;
  height:400px;
  overflow:scroll;
}

.loader-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: unset !important;
  width: unset !important;
}
.md-table-head {
  background: #ebedee;
}
.autoconnect .md-checkbox .md-checkbox-label{
  position: unset !important;
}

.custom-checkbox .md-checkbox-label {
  color: unset !important;
}

.custom-radio .md-radio-label {
  color: unset !important;
  font-weight: bold;
}

.screencellbook {
    width: 100px;
    height: 125px;
    font-size: 12px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    margin: 5px;
    padding: 3px;
}

.screencellbook>img {
    display: inline-block;
    max-width: 100%;
    max-height: 65%;
}

.badge-icon {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #f1c7c5;
    border-radius: 5px;
    font-size: 14px;
}

.screencellbook .apptext {
    /*float: left;
    width: 100%;
    margin-top: 0px;*/
    color: rgb(0, 0, 0);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 0;
    word-break: break-all;
}
.md-menu-content{
  max-width: 45% !important;
}
.text-fields-row {
  display: flex;
}
.md-dialog-actions {
  position: unset !important;
}
.md-card-tabs .md-list{
  flex-flow: wrap !important;
}
.mt-2{
  margin-top: 2px !important;
}
.f-right-padding {
    width: 12%;
    text-align: right;
    float: right;
    margin-right: 2px;
}

.ul-class {
    list-style-type: none;
    white-space: nowrap;
    overflow: auto;
    width: 100%;
}

.li-class {
  display: inline-block;
  text-align: center;
}

.radio-d-none {
  display: none;
}

.app-icon-single {
    height: 75px !important;
    width: 75px !important;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}
.d-block {
  display: block;
}
.label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.label img {
  height: 75px;
  width: 75px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + .label {
  border-color: #ddd;
}

:checked + .label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:not(:checked) + .label:before {
  content: "";
  background-color: #ffffff;
  transform: scale(0.5);
}

:checked + .label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}
input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}
input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}

.w-50 {
  width: 50%;
}

#product-directory {
  padding: unset;
  box-sizing: border-box;
}
#product-directory ul {
  padding-left: 0;
  box-sizing: border-box;
}
#product-directory li {
  list-style: none;
  box-sizing: border-box;
  display: inline-block;
  width: 140px;
  margin: 5px;
  position: relative;            
  border: 1px solid #d3d3d3;
  align-content: center;
  display: inline-block; 
  vertical-align: top;
}
#product-directory li img {
  max-width: 65%;
  height: 85px;
  margin: 0 auto;
  position: relative;
  align-self: center;
  margin:0 auto;
  display:block;
}
#product-directory li span {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  /*background-color: #6a6969;*/
  padding: 5px;
  color: black;
  min-height: 34px;
  word-break: break-all;
  height: 75px;
}

</style>